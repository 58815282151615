<template>
  <div class="w-full">
    <vs-row vs-type="flex" vs-w="12">
      <!-- SELECAO DE ZONA -->
      <vs-col vs-w="3">
        <vx-card title="Zonas">
          <template slot="actions">
            <CadastroZona @update="getZonas" />
          </template>

          <vs-list
            v-for="(zona, idx) in zonas"
            v-bind:key="`Z-${idx}`"
            :class="zonaAtual != zona.id ? 'list-item' : 'active-list-item'"
          >
            <div
              @click="zonaAtual = zona.id"
              class="mb-2"
              style="cursor: pointer"
            >
              <vs-list-item
                :title="zona.nome"
                :subtitle="qtdBairrosNaZona(zona) + ' bairro(s)'"
              >
                <div class="flex">
                  <EditarZona :zona="zona" @update="getZonaNome" />
                </div>
              </vs-list-item>
            </div>
          </vs-list>
        </vx-card>
      </vs-col>
      <vs-col vs-w="0.1"></vs-col>
      <!-- BAIRROS -->
      <vs-col vs-w="8.9">
        <vx-card>
          <vs-table
            notSpacer
            multiple
            v-model="bairrosZona"
            :data="bairros"
            search
            max-items="9"
            maxHeight="68vh"
            pagination
            color="dark"
          >
            <template slot="header">
              <vs-row vs-w="12">
                <vs-col vs-w="7">
                  <h4 style="color: #b4aa99">Bairros</h4>
                </vs-col>
                <vs-col vs-w="5">
                  <CadastroBairro @update="getBairros" />
                  <SuccessButton
                    v-if="zonaAtual"
                    class="mr-6"
                    :disabled="!$atividades.permissoes.includes(14)"
                    text="Salvar"
                    icon="save"
                    type="border"
                    @func="update"
                  ></SuccessButton>
                </vs-col>
              </vs-row>
            </template>
            <template slot="thead" notSpacer>
              <vs-th>Nome</vs-th>
              <vs-th>Cidade</vs-th>
              <vs-th>Estado</vs-th>
              <vs-th>Zona</vs-th>
              <vs-th>Ações</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr
                notSpacer
                :state="data[indextr].lixeira == 1 ? 'danger' : ''"
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data"
              >
                <vs-td :data="data[indextr].nome">
                  {{ data[indextr].nome }}
                </vs-td>
                <vs-td :data="data[indextr].cidade">
                  {{ data[indextr].cidade }}
                </vs-td>
                <vs-td :data="data[indextr].estado">
                  {{ data[indextr].estado }}
                </vs-td>
                <vs-td :data="data[indextr].id_zona">
                  {{ getZonaNome(data[indextr].id_zona) }}
                </vs-td>
                <vs-td>
                  <ModalEditarBairro
                    :bairro="data[indextr]"
                    @update="getBairros"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import CadastroZona from "./cadastroZona"
import CadastroBairro from "./cadastroBairro"
import EditarZona from "./editarZona"
import ModalEditarBairro from "./editarBairro"
import components from "@/components/default/exports.js"
import Notify from "@/functions/notify/success.js"

export default {
  data () {
    return {
      zonas: [],
      bairros: [],
      bairrosZona: [],
      cidades: [],
      estados: [],
      zonaAtual: null
    }
  },
  methods: {
    async getZonas () {
      //this.$vs.loading();
      try {
        this.zonas = await this.$http.get(`zona`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
      }
    },
    async getBairros () {
      this.$vs.loading()
      try {
        this.bairros = await this.$http.get(`bairro`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
      }
    },
    getZonaNome (id) {
      const zona = this.zonas.find(x => x.id == id)
      if (zona == undefined || zona == null) return "Sem zona"
      return zona.nome
    },
    async update () {
      this.$vs.loading()
      for (let i = 0; i < this.bairros.length; i++) {
        for (let j = 0; j < this.bairrosZona.length; j++) {
          if (this.bairros[i].id == this.bairrosZona[j].id) {
            //delete this.bairros[i].cidade;
            //delete this.bairros[i].estado;
            this.bairros[i].id_zona = this.zonaAtual
          }
        }
      }

      try {
        for (let j = 0; j < this.bairrosZona.length; j++) {
          delete this.bairrosZona[j].cidade
          delete this.bairrosZona[j].estado
        }
        await this.$http.put("bairro_zona", this.bairrosZona)
        this.$vs.notify(Notify)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
        this.getBairros()
      }
    },
    qtdBairrosNaZona (zona) {
      let qtd = 0
      for (let i = 0; i < this.bairros.length; i++) {
        if (this.bairros[i].id_zona == zona.id) qtd++
      }
      return qtd
    }
  },
  components: {
    ...components,
    CadastroZona,
    CadastroBairro,
    EditarZona,
    ModalEditarBairro
  },
  mounted () {
    this.getZonas()
    this.getBairros()
  }
};
</script>

<style>
.dark {
  background-color: #000;
}

.vs-list--subtitle {
  font-size: 13px !important;
}

.vs-radio--circle,
.vs-radio--borde {
  width: 25px !important;
  height: 25px !important;
}

.el-button.is-circle {
  border-color: transparent !important;
}
.list-item {
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  border-radius: 5px;
  font-size: 16px;
}
.list-item:hover {
  background: #dcdcdc;
}
.active-list-item {
  background-color: #000a12;
  transition: background 0.1s ease-in-out;
  border-radius: 5px;
  font-size: 16px;
  color: #ffca00;
}

.col {
  width: auto;
}
</style>
