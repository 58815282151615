<template>
  <div class="w-full">
    <SuccessButton
      :disabled="!$atividades.permissoes.includes(14)"
      class="mr-2"
      @func="ModalCadastroZona = true"
    ></SuccessButton>
    <SideBar
      title="Cadastrar zona"
      @hide="ModalCadastroZona = false"
      :active="ModalCadastroZona"
      @success="submitForm()"
      size="small"
    >
      <div slot="body">
        <div id="cadastrarZona" class="vs-con-loading__container w-full">
          <vs-row class="mb-4" vs-type="flex" vs-w="12">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <div class="w-full py-2">
                <vs-input
                  class="inputx w-full"
                  name="zonaName"
                  v-validate="'required'"
                  label="Nome"
                  v-model="zona.nome"
                />
                <vs-row>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('zona')"
                    >{{ $validators.empty }}</span
                  >
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  data () {
    return {
      ModalCadastroZona: false,
      zona: {},
      error: false
    };
  },
  methods: {
    async submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.cadastrarZona();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async cadastrarZona () {
      await this.$vs.loading({ container: "#cadastrarZona", scale: 0.6 });
      try {
        const res = await this.$http.post(`zona`, this.zona);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'cadastrar',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Cadastro da zona N°' + res.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.zona = {};
        this.ModalCadastroZona = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$emit("update");
        await this.$vs.loading.close("#cadastrarZona > .con-vs-loading");
      }
    }
  },
  components: components
};
</script>

<style></style>
