<template>
  <div class="w-full">
    <SuccessButton
      :disabled="!$atividades.permissoes.includes(14)"
      text="Cadastrar bairro"
      class="mr-6"
      @func="ModalCadastroBairro = true"
    ></SuccessButton>
    <SideBar
      title="Cadastrar bairro"
      @hide="ModalCadastroBairro = false"
      :active="ModalCadastroBairro"
      @success="submitForm()"
      size="large"
    >
      <div slot="body">
        <div id="cadastrarBairro" class="vs-con-loading__container w-full">
          <vs-row class="mb-4" vs-type="flex" vs-w="12">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <div class="w-full py-2">
                <vs-input
                  class="w-full"
                  name="bairroName"
                  v-validate="'required'"
                  label="Nome do bairro "
                  v-model="bairro.nome"
                />
                <vs-row>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('bairroName')"
                    >{{ $validators.empty }}</span
                  >
                </vs-row>
              </div>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <div class="w-full py-2">
                <label style="color: black; font-size: 0.85rem" class="pl-2"
                  >Cidade</label
                >
                <el-select
                  filterable
                  clearable
                  class="w-full"
                  name="cidade"
                  v-validate="'required'"
                  v-model="bairro.id_cidade"
                  :popper-append-to-body="false"
                  remote
                  :remote-method="filtroCidade"
                  placeholder="Pesquise"
                  loading-text="Buscando..."
                  :loading="loading"
                >
                  <el-option
                    v-for="cidade in cidades"
                    :value="cidade.id"
                    :label="cidade.nome"
                    :key="cidade.id"
                  ></el-option>
                </el-select>
                <vs-row>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('cidade')"
                    >{{ $validators.empty }}</span
                  >
                </vs-row>
              </div>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <div class="w-full py-2">
                <label style="color: black; font-size: 0.85rem" class="pl-2"
                  >Zona</label
                >
                <el-select
                  filterable
                  clearable
                  class="w-full"
                  name="zona"
                  v-model="bairro.id_zona"
                  :popper-append-to-body="false"
                  remote
                  :remote-method="filtroZona"
                  placeholder="Pesquise"
                  loading-text="Buscando..."
                  :loading="loading"
                >
                  <el-option
                    v-for="zona in zonas"
                    :value="zona.id"
                    :label="zona.nome"
                    :key="zona.id"
                  ></el-option>
                </el-select>
                <vs-row>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('zona')"
                    >{{ $validators.empty }}</span
                  >
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";
import vSelect from "vue-select";

export default {
  data () {
    return {
      ModalCadastroBairro: false,
      bairro: {},
      zonas: [],
      cidades: [],
      error: false,
      loading: false
    };
  },
  methods: {
    async submitForm () {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.cadastrarBairro();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger",
            });
          }
        });
      });
    },
    async cadastrarBairro () {
      await this.$vs.loading({ container: "#cadastrarBairro", scale: 0.6 });
      try {
        const res = await this.$http.post(`bairro`, this.bairro);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "cadastrar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Cadastro do bairro N°" + res.id,
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.bairro = {};
        this.ModalCadastroBairro = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$emit("update");
        await this.$vs.loading.close("#cadastrarBairro > .con-vs-loading");
      }
    },
    async filtroCidade (query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getCidadesByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async filtroZona (query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getZonasByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async getZonasByQuery (query) {
      try {
        this.zonas = await this.$http.post(`getZonasByQuery`, { query });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getCidadesByQuery (query) {
      try {
        this.cidades = await this.$http.post(`getCidadesByQuery`, { query });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
  },
  mounted () {
  },
  components: { ...components, vSelect },
};
</script>

<style></style>
