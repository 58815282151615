<template>
  <div>
    <EditButton
      :disabled="!$atividades.permissoes.includes(14)"
      @func="openModal()"
    ></EditButton>

    <SideBar
      title="Editar zona"
      @hide="cancelar"
      :active="modalEditarZona"
      size="small"
      textSucces="Atualizar"
      :item="zona"
      :disabled="!$atividades.permissoes.includes(15)"
      @excluir="excluirZona()"
      @status="mudarStatusZona()"
      icon="edit"
      @success="submitForm()"
    >
      <div slot="body">
        <div id="editarZona" class="vs-con-loading__container w-full">
          <vs-row class="mb-4" vs-type="flex" vs-w="12">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <div class="w-full py-2">
                <vs-input
                  class="inputx w-full"
                  name="zona"
                  v-validate="'required'"
                  label="Nome"
                  v-model="zona.nome"
                />
                <vs-row>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('zona')"
                    >{{ $validators.empty }}</span
                  >
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "modalEditarZona",
  props: { zona: Object },
  data () {
    return {
      modalEditarZona: false,
      error: false,
      originalData: {}
    };
  },
  methods: {
    async openModal () {
      this._beforeEditingCache = Object.assign({}, this.zona);
      this.originalData = this.zona;
      this.modalEditarZona = true;
    },
    async submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.editarZona();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async editarZona () {
      await this.$vs.loading({ container: "#editarZona", scale: 0.6 });
      try {
        const res = await this.$http.put(`zona/${this.zona.id}`, this.zona);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Edição da zona N°" + res.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarZona = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#editarZona > .con-vs-loading");
      }
    },
    async excluirZona () {
      await this.$vs.loading({ container: "#editarZona", scale: 0.6 });
      try {
        await this.$http.delete(`zona/${this.zona.id}`);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "excluir",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Exclusão da zona N°" + this.zona.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarZona = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$emit("update");
        await this.$vs.loading.close("#editarZona > .con-vs-loading");
      }
    },
    async mudarStatusZona () {
      await this.$vs.loading({ container: "#editarZona", scale: 0.6 });
      try {
        const res = await this.$http.put(`zona/${this.zona.id}`, {
          lixeira: !this.zona.lixeira
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Mudança do status da zona N°" + res.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarZona = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$emit("update");
        await this.$vs.loading.close("#editarZona > .con-vs-loading");
      }
    },
    async cancelar () {
      Object.assign(this.zona, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.modalEditarZona = false;
    }
  },
  components: components
};
</script>

<style>
.vs-popup--title {
  color: white !important;
}
</style>
