<template>
  <div>
    <EditButton
      :disabled="!$atividades.permissoes.includes(14)"
      @func="openModal()"
    ></EditButton>

    <SideBar
      title="Editar bairro"
      @hide="cancelar"
      :active="modalEditarBairro"
      size="large"
      textSucces="Atualizar"
      :disabled="!$atividades.permissoes.includes(15)"
      :item="bairro"
      @excluir="excluirBairro()"
      @status="mudarStatusBairro()"
      icon="edit"
      @success="submitForm()"
    >
      <div slot="body">
        <div id="editarBairro" class="vs-con-loading__container w-full">
          <vs-row class="mb-4" vs-type="flex" vs-w="12">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <div class="w-full py-2">
                <vs-input
                  class="w-full"
                  name="bairroName"
                  v-validate="'required'"
                  label="Nome do bairro "
                  v-model="bairro.nome"
                />
                <vs-row>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('bairroName')"
                    >{{ $validators.empty }}</span
                  >
                </vs-row>
              </div>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <div class="w-full py-2">
                <label style="color: black; font-size: 0.85rem" class="pl-2"
                  >Cidade</label
                >
                <el-select
                  filterable
                  clearable
                  class="w-full"
                  name="cidade"
                  v-validate="'required'"
                  v-model="bairro.id_cidade"
                  :popper-append-to-body="false"
                  remote
                  :remote-method="filtroCidade"
                  placeholder="Pesquise"
                  loading-text="Buscando..."
                  :loading="loading"
                >
                  <el-option
                    v-for="cidade in cidades"
                    :value="cidade.id"
                    :label="cidade.nome"
                    :key="cidade.id"
                  ></el-option>
                </el-select>
                <vs-row>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('cidade')"
                    >{{ $validators.empty }}</span
                  >
                </vs-row>
              </div>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <div class="w-full py-2">
                <label style="color: black; font-size: 0.85rem" class="pl-2"
                  >Zona</label
                >
                <el-select
                  filterable
                  clearable
                  class="w-full"
                  name="zona"
                  v-model="bairro.id_zona"
                  :popper-append-to-body="false"
                  remote
                  :remote-method="filtroZona"
                  placeholder="Pesquise"
                  loading-text="Buscando..."
                  :loading="loading"
                >
                  <el-option
                    v-for="zona in zonas"
                    :value="zona.id"
                    :label="zona.nome"
                    :key="zona.id"
                  ></el-option>
                </el-select>
                <vs-row>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('zona')"
                    >{{ $validators.empty }}</span
                  >
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "ModalEditarBairro",
  props: { bairro: Object },
  data () {
    return {
      modalEditarBairro: false,
      zonas: [],
      cidades: [],
      error: false,
      originalData: {},
      loading: false,
    };
  },
  methods: {
    async openModal () {
      this.modalEditarBairro = true;
      await this.$vs.loading({ container: "#editarBairro", scale: 0.6 });
      try {
        this._beforeEditingCache = Object.assign({}, this.bairro);
        this.originalData = this.bairro;
        await this.getCidadesByQuery(this.bairro.id_cidade);
        await this.getZonasByQuery(this.bairro.id_zona);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#editarBairro > .con-vs-loading");
      }
    },
    async submitForm () {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.editarBairro();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            });
          }
        });
      });
    },
    async editarBairro () {
      await this.$vs.loading({ container: "#editarBairro", scale: 0.6 });
      try {
        const bairro = this.bairro;
        delete bairro.cidade;
        delete bairro.estado;
        const res = await this.$http.put(`bairro/${this.bairro.id}`, bairro);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "update",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Edição do bairro N°" + res.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarBairro = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$emit("update");
        await this.$vs.loading.close("#editarBairro > .con-vs-loading");
      }
    },
    async excluirBairro () {
      await this.$vs.loading({ container: "#editarBairro", scale: 0.6 });
      try {
        await this.$http.delete(`bairro/${this.bairro.id}`);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'excluir',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Exclusão do bairro N°' + this.bairro.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarBairro = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#editarBairro > .con-vs-loading");
        this.$emit("update");
      }
    },
    async mudarStatusBairro () {
      await this.$vs.loading({ container: "#editarBairro", scale: 0.6 });
      try {
        const res = await this.$http.put(`bairro/${this.bairro.id}`, {
          lixeira: !this.bairro.lixeira
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'atualizar',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Mudança do status do bairro N°' + res.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarBairro = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#editarBairro > .con-vs-loading");
        this.$emit("update");
      }
    },
    async filtroCidade (query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getCidadesByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async filtroZona (query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getZonasByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async getZonasByQuery (query) {
      try {
        this.zonas = await this.$http.post(`getZonasByQuery`, { query });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getCidadesByQuery (query) {
      try {
        this.cidades = await this.$http.post(`getCidadesByQuery`, { query });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async cancelar () {
      Object.assign(this.bairro, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.modalEditarBairro = false;
    }
  },
  components: components
};
</script>

<style>
.vs-popup--title {
  color: white !important;
}
</style>
